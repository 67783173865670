<template>
    <v-card flat>
        <v-col align-start pl-2 pr-2 cols="12" v-show="show">
            <v-img aspect-ratio="2" contain max-height="250"
                :src="item.imageLarge"
            >
            </v-img>
            <br>
            <p>{{item.description}}</p>
            <p>${{item.price}} per {{item.size}}. Limit {{item.limit}} per person.</p>
            <div v-if="showDates">
                <strong>Order Window: </strong>{{ formatDate(item.orderStart) }} - {{ formatDate(item.orderEnd) }}<br>
                <strong>Pickup Window: </strong>{{ formatDate(item.pickupStart) }} - {{ formatDate(item.pickupEnd) }}<br>
            </div>
            <div v-if="item.fulfillments">
                <strong>Pickup Locations: </strong>
                <span v-for="(fulfillment, index) in item.fulfillments" :key="fulfillment.location">{{ fulfillment.location + (index < item.fulfillments.length-1 ? ',':'') }} </span><br>
            </div>
        </v-col>
    </v-card>
</template>

<script>
import moment from 'moment'
import AllMixins from '../../mixins/AllMixins'
export default {
    props: {
        item: {type: Object, required: true},
        show: {type: Boolean, required: false},
        showDates: {type: Boolean, required: false}
    },
    mixins: [AllMixins],
    methods: {
        formatDate (dateStr) {
            return moment(dateStr, "YYYY-MM-DD @HH:mm").format("M/D/YYYY ha")
        },
    }
}
</script>